/*
 * @Description:
 * @Author: 老纪
 * @Date: 2022-12-22 10:40:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-08 13:29:44
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import request from "@/utils/request"; //axios实例化后引入取名http
import config from "@/utils/config";
import Tools from "@/utils/index";
Vue.prototype.$tools = new Tools();
// 引入echarts
// import echarts from "echarts";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/base.less"; // ruoyi css
// 将自动注册所有组件为全局组件
import dataV from "@jiaminghi/data-view";

Vue.prototype.$MAX_HEIGHT = (function () {
  return (document.documentElement.clientHeight - 420) / 3 + "px";
})();

Vue.prototype.request = request;
Vue.prototype.config = config;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(dataV);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
