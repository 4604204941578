/*
 * @Description:
 * @Author: 老纪
 * @Date: 2022-11-02 18:24:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-20 11:14:00
 */
class Tools {
  //获取时间 2022年11月02日  18:35:06
  curDay() {
    let obj = {}
    var date = new Date()
    var year = date.getFullYear()
    var month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() //获取当前时间
    var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    obj.day = year + '年' + month + '月' + day + '日'
    obj.time = hour + ':' + m + ':' + s
    return obj
  }
  //获取近几年的年份（若 val不传，默认是近5年）
  recentYear(val) {
    var num = val
    if (!num) {
      num = 4
    }
    var myDate = new Date()
    var curYear = myDate.getFullYear()
    var year1 = curYear - num
    let arr = []
    for (var i = year1; i <= curYear; i++) {
      arr.push(i)
    }
    return arr
  }
  //获取当前年份
  curYear() {
    var curYear = new Date().getFullYear()
    return curYear
  }
  //移出数组中的最后一个元素
  truncate(arr) {
    return arr.slice(0, -1)
  }
  // 图片回显路径 tag=0 回显 tag=1 下载
  getFilePath(fileId, tag = 0) {
    // return `http://192.168.4.150:4100/stage-api/file/download?download=${tag}&fileId=${fileId}`
    return `https://guanyu.0769aj.com/stage-api/file/download?download=${tag}&fileId=${fileId}`
  }
}
export default Tools
