/*
 * @Description:
 * @Author: 老纪
 * @Date: 2022-08-22 13:51:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-28 17:24:12
 */
/**
 * 基础配置
 */
export default {
  //公司服务器地址
  baseUrl: 'http://guanyu.0769aj.com/stage-api/', //生产环境
  // baseUrl: 'http://192.168.4.38:10101/', //志雄ip
  // baseUrl: 'http://192.168.4.39:10101',
  // 生产环境服务器地址（生产环境无需IP和端口）
  // baseUrl: "/stage-api/", //打包会自动映射
}
