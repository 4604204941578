/*
 * @Description:
 * @Author: 老纪
 * @Date: 2022-10-29 17:15:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-15 13:28:11
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
  },
  {
    path: "/house",
    name: "house",
    component: () =>
      import(/* webpackChunkName: "house" */ "@/views/house/index.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () =>
      import(/* webpackChunkName: "service" */ "@/views/service/index.vue"),
  },
  {
    path: "/contract",
    name: "contract",
    component: () =>
      import(/* webpackChunkName: "contract" */ "@/views/contract/index.vue"),
  },
  {
    path: "/",
    // name: "home",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
