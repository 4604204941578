/*
 * @Description:
 * @Author: 老纪
 * @Date: 2022-10-31 16:17:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-11-02 11:23:13
 */
import axios from "axios";
import config from "@/utils/config.js";

let prefix = config.baseUrl;
// + config.baseApiUrl;

// 创建axios实例
const service = axios.create({
  baseURL: prefix,
  timeout: 5000,
  headers: {
    "content-type": "application/json",
  },
});

// 响应拦截器
service.interceptors.response.use(
  (config) => {
    if (200 === config.data.code) {
      let { data } = config;
      return undefined === data ? {} : data;
    } else {
      return Promise.reject(new Error("请求数据异常"));
    }
  },
  (error) => {
    return Promise.reject(new Error(error));
  }
);
export default service;
