<!--
 * @Description: 
 * @Author: 老纪
 * @Date: 2022-10-29 17:49:18
 * @LastEditors: 请留下大名
 * @LastEditTime: 2024-03-06 12:00:01
-->
<template>
  <div id="app" @mouseup="toUp">
    <dv-full-screen-container>
      <AppBar />
      <keep-alive>
        <router-view />
      </keep-alive>
    </dv-full-screen-container>
  </div>
</template>
<script>
import AppBar from "./components/AppBar/index.vue";
export default {
  name: "App",
  data() {
    return {
      index: 0, //索引记录
      time: null,
    };
  },
  components: {
    AppBar,
  },
  created() {
    // this.autoPlay()
  },
  methods: {
    autoPlay() {
      this.time = setTimeout(() => {
        if (this.index == 3) {
          this.index = 0;
        } else {
          this.index += 1;
        }

        switch (this.index) {
          case 0:
            this.$router.push({ path: "/home" });
            break;
          case 1:
            this.$router.push({ path: "/house" });
            break;
          case 2:
            this.$router.push({ path: "/service" });
            break;
          case 3:
            this.$router.push({ path: "/contract" });
            break;
        }
      }, 1000 * 60 * 1);
    },
    toUp() {
      clearInterval(this.time); //关闭dingshiq

      this.time = setTimeout(() => {
        this.autoPlay();
      }, 1000 * 60 * 2);
    },
  },
  watch: {
    $route(val, oldVal) {
      clearInterval(this.time); //关闭dingshiq
      const paths = {
        "/home": 0,
        "/house": 1,
        "/service": 2,
        "/contract": 3,
      };
      const key = val.path;
      this.index = paths[key];
      this.autoPlay();
    },
  },
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
  background: #ecf5f7;
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}
</style>
