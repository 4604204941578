<template>
  <div class="barItem">
    <ul>
      <li v-for="item in links" :key="item.to">
        <router-link
          :to="item.to"
          tag="div"
          active-class="active"
          class="router-link-item"
          >{{ item.title }}</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BarItem',
  props: {
    links: {
      type: Array,
      default: () => {
        return [
          {
            to: '/home',
            title: '安居首页',
          },
          {
            to: '/house',
            title: '房源总览',
          },
          {
            to: '/service',
            title: '服务总览',
          },
          {
            to: '/contract',
            title: '客户总览',
          },
        ]
      },
    },
  },
}
</script>
<style lang="less" scoped>
.barItem {
  border-style: solid;
  border-color: rgba(240, 236, 236, 0.4);
  background-color: rgba(231, 228, 228, 0.4);
  width: 100%;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding-right: 36px;

  ul {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  li {
    list-style: none;
    color: #ffffff;
  }
  .router-link-item {
    cursor: pointer;
    transition: 1s all;
    border-radius: 50px;
    color: #ffffff;
  }
  .active {
    border-radius: 50px;
    padding: 5px 20px;
    color: #ffffff;
    background-color: #2862b7;
    border-style: solid;
    border-color: #ffffff;
    font-weight: 600;
  }
}
</style>
