<!--
 * @Description: 固定顶部导航栏
 * @Author: 老纪
 * @Date: 2022-10-29 17:50:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-07 17:54:52
-->
<template>
  <div class="top-box">
    <!-- 左边 -->
    <div class="left-box">
      <img :src="logo" />
      <p class="line mr15"></p>
      <div class="title-box">
        <img class="title-img" :src="icon" />
      </div>
    </div>
    <!-- 中间 -->
    <div class="center-box">
      <BarItem :links="route1" />
    </div>
    <!-- 右边 -->
    <div class="right-box">
      <img v-if="weatherIcon" class="weather-icon" :src="weatherIcon" />
      <div class="right-left mr15 ml20">
        <div class="fs14">{{ weather["weather"] }} {{ weather["temperature"] }}</div>
        <div class="fs8">{{ weather["environment"] }}</div>
      </div>
      <p class="line mr15"></p>
      <div class="right-right">
        <div class="fs16">{{ curTime.time }}</div>
        <div class="fs8">{{ weather.date }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BarItem from "@/components/BarItem/index.vue"
import logoImg from "@/assets/logo.png"
export default {
  name: "AppBar",
  props: {},
  data() {
    return {
      logo: logoImg, //莞寓logo,
      route1: [
        {
          to: "/home",
          title: "安居首页"
        },
        {
          to: "/house",
          title: "房源总览"
        },
        {
          to: "/service",
          title: "服务总览"
        },
        {
          to: "/contract",
          title: "客户总览"
        }
      ],
      curTime: {}, //获取当前时间
      weather: {}, //天气
      icon: require("@/assets/title.png"),
      weatherIcon: "" //require("@/assets/sun.png")
    }
  },
  components: {
    BarItem
  },
  created() {
    this.getDay()
    this.init()
  },

  methods: {
    getDay() {
      this.curTime = this.$tools.curDay()
      this.time = setTimeout(() => {
        this.getDay()
      }, 1000)
    },
    //获取当前天气
    init() {
      this.request.get("/view/serve/analyse/getWeather").then((res) => {
        this.weather = res.data
        if (res.data.weather.includes("雨")) {
          this.weatherIcon = require("@/assets/rain.png")
        } else if (res.data.weather.includes("云")) {
          this.weatherIcon = require("@/assets/cloudy.png")
        } else if (res.data.weather.includes("晴")) {
          this.weatherIcon = require("@/assets/sun.png")
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.top-box {
  background-image: url("@/assets/top.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;

  height: 100px;
  // width: 100%;
  .left-box {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      width: 144px;
      height: 44px;
      margin-right: 15px;
    }
    .line {
      width: 2px;
      height: 50px;
      background-color: #326fa6;
    }
    .title-box {
      width: 364px;
      height: 56px;
      .title-img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .center-box {
    width: 25%;
  }
  .right-box {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    .right-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .right-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .line {
      width: 2px;
      height: 50px;
      background-color: #f26055;
    }
  }
}

.font-color {
  color: #ffffff;
}
.mr15 {
  margin-right: 15px;
}
.fs14 {
  font-size: 14px;
  color: #ffffff;
}
.fs16 {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.fs8 {
  font-size: 8px;
  color: #f8a8a2;
}
</style>